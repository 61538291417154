






































































import Vue, { PropType } from "vue";
import { AssessmentSummary } from "@/interfaces/assessments";
import { get_random_color } from "@/utils/colors";

export default Vue.extend({
  name: "ContextualArchetype",
  methods: { get_random_color },
  data() {
    return {
      liked1: "",
      liked2: ""
    };
  },
  props: {
    contextual: {
      type: Object as PropType<AssessmentSummary.ContextualArchetype>,
      required: true
    }
  },
  mounted() {
    const most_liked = this.contextual.mostLikeYou;
    if (most_liked.length >= 2) {
      this.liked1 = most_liked[0].archetype.displayName;
      this.liked2 = most_liked[1].archetype.displayName;
    }
  }
});
