import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";

export interface AnswersRubric {
  label: string;
  value: string;
}
export interface AutocompleteAssessmentSearch {
  date_picker_menu: boolean;
  date_picker_field: string[];
  date_range_value: string | null;
  selected_searched_user: SearchUserObject[];
}

export namespace AssessmentSummary {
  export interface Root {
    scores: Scores;
    metadata: Metadata;
  }

  export interface Metadata {
    startTime: string;
    completedTime: string;
  }

  export interface Scores {
    dimensions: Dimension[];
    youStatements: YouStatement[];
    contextualArchetype: ContextualArchetype;
    allArchetypes: Archetype[];
    archetypeGroupings: ArchetypeGroupings;
  }

  export interface Archetype {
    key: string;
    displayName: string;
    percentile: number;
    statements: Statements;
    name: null | string;
    uuid: null | string;
    rawScore: number;
    adjustedScore: number | null;
  }

  export interface Statements {
    prototypeGroup: string;
    portrait: string[];
    talents: string[];
    growthOpportunities: string[];
    shortBlurb: string;
    expandedBlurb: string;
  }

  export interface ArchetypeGroupings {
    Advocates: string;
    Architects: string;
    Creators: string;
    Enthusiasts: string;
    Fighters: string;
    Givers: string;
    Individualists: string;
    Leaders: string;
    Producers: string;
    Seekers: string;
  }

  export interface ContextualArchetype {
    mostLikeYou: StLikeYou[];
    leastLikeYou: StLikeYou[];
    yourArchetype: Archetype;
    under60: boolean;
    under80: boolean;
    topArchetypesHeader: string;
    alsoLikeArchetypes: string;
    leastLikeArchetypes: string;
    mostLikeArchetype: string;
    topThreeArchetypeExplanation: string;
    topThreeHeader: string;
    topThreeMostLike: string;
  }

  export interface StLikeYou {
    archetype: Archetype;
    matchQuality: string;
  }

  export interface Dimension {
    displayName: string;
    key: string;
    percentileInstructionsContext: string[];
    traits: Trait[];
    color: string;
  }

  export interface Trait {
    key: string;
    uuid: string;
    displayName: string;
    traitDescription: string[];
    order: number;
    percentile: number;
    facetPatternStatement: string[];
    quintile: Quintile;
    facets: Facet[];
    rawScore: number;
  }

  export interface Facet {
    key: string;
    uuid: string;
    displayName: string;
    order: number;
    percentile: number;
    targetedFacetStatement: string[];
    quintile: Quintile;
    rawScore: number;
  }

  export enum Quintile {
    high = "High ",
    low = "Low ",
    moderate = "Moderate ",
    very_low = "Very Low "
  }

  export interface YouStatement {
    scenario: string;
    behaviors: string[];
  }
  export enum ArcheTypesKeys {
    individualist = "individualist",
    inventor = "inventor",
    problemsolver = "problemsolver",
    helper = "helper"
  }
}
