















import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "IndividualSummary",
  props: {
    individualist_summary: {
      type: Array as PropType<string[]>,
      required: true
    }
  }
});
